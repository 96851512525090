

import {Vue, Component} from 'vue-property-decorator';
import {BIconDownload, BDropdown} from "bootstrap-vue";
import {Ax} from '@/utils';
import axios from "axios";
import DatePicker from 'vue2-datepicker';
import AlterationI18nMixin from "@/modules/budget/alteration/mixins/alteration-i18n-mixin";
import {getActualVariant} from "@/modules/budget/budgetVariantService";


interface Region {
    code: string;
}
@Component({
    components: {BIconDownload, BDropdown, DatePicker},
    mixins: [ AlterationI18nMixin ],

})


export default class BudgetAccounting extends Vue {
    report_list = [
        {
            "id": 1,
            "name_ru": "Приложение 1",
            "name_kz": "1-қосымша",
            "description_ru": "Книга Журнал-главная",
            "description_kz": "Бас-журнал кітабы",
            "category": "МБ",
            "load_url": "ba-report-1"
        },
        {
            "id": 2,
            "name_ru": "Приложение 2",
            "name_kz": "2-қосымша",
            "description_ru": "Мемориальный ордер по единому казначейскому счету",
            "description_kz": "Бірыңғай қазынашылық шоты бойынша мемориалдық ордер",
            "category": "РБ"
        },
        {
            "id": 3,
            "name_ru": "Приложение 3",
            "name_kz": "3-қосымша",
            "description_ru": "Мемориальный ордер",
            "description_kz": "3-қосымша Мемориалдық ордер",
            "category": "МБ",
            "load_url": "ba-report-3"
        },
        {
            "id": 4,
            "name_ru": "Приложение 4",
            "name_kz": "4-қосымша",
            "description_ru": "Расшифровка по счетам",
            "description_kz": "Қолма-қол ақшаны бақылау шоттардың толық жазылуы",
            "category": "МБ"
        },
        {
            "id": 5,
            "name_ru": "Приложение 5",
            "name_kz": "5-қосымша",
            "description_ru": "Расшифровка размещенных с Единого казначейского счета сумм во вклады (депозиты)",
            "description_kz": "Бірыңғай қазынашылық шоттан салымдарға (депозиттерге) орналастырылған сомалардың толық жазылуы",
            "category": "РБ"
        },
        {
            "id": 6,
            "name_ru": "Приложение 6",
            "name_kz": "6-қосымша",
            "description_ru": "Расшифровка размещенных сумм депозитов с местных бюджетов",
            "description_kz": "Жергілікті бюджеттерден депозиттерге орналастырылған сомалардың толық жазылуы",
            "category": "МБ"
        },
        {
            "id": 7,
            "name_ru": "Приложение 7",
            "name_kz": "7-қосымша",
            "description_ru": "Расшифровка по поступлениям и расходам бюджета",
            "description_kz": "Бюджеттердің түсімдері мен шығыстардың толық жазылуы",
            "category": "МБ"
        },
        {
            "id": 8,
            "name_ru": "Приложение 8",
            "name_kz": "8-қосымша",
            "description_ru": "Баланс Комитета казначейства",
            "description_kz": "Қазынашылық комитетінің балансы",
            "category": "РБ"
        },
        {
            "id": 9,
            "name_ru": "Приложение 9",
            "name_kz": "9-қосымша",
            "description_ru": "Баланс исполнения местного бюджета",
            "description_kz": "Жергілікті бюджеттің атқарылу балансы",
            "category": "МБ",
            "load_url": "ba-report-9"
        },
        {
            "id": 10,
            "name_ru": "Приложение 10",
            "name_kz": "10-қосымша",
            "description_ru": "Отчет о движении денег на Едином казначейском счете",
            "description_kz": "Бірыңғай қазынашылық шоттағы ақша қозғалысы туралы есеп",
            "category": "РБ"
        },
        {
            "id": 11,
            "name_ru": "Приложение 12",
            "name_kz": "12-қосымша",
            "description_ru": "Отчет о государственном и гарантированном государством долге, долг по поручительствам государства",
            "description_kz": "Мемлекеттік және мемлекет кепілдік берген борыш, мемлекеттің кепілгерлері бойынша қарыздары туралы есеп",
            "category": "РБ"
        },
        {
            "id": 12,
            "name_ru": "Приложение 14",
            "name_kz": "14-қосымша",
            "description_ru": "Отчет по внешним займам и связанным грантам",
            "description_kz": "Сыртқы қарыздар және байланысты гранттар жөніндегі есеп",
            "category": "МБ"
        },
        {
            "id": 13,
            "name_ru": "Приложение 15",
            "name_kz": "15-қосымша",
            "description_ru": "Информация по расходованию резерва Правительства Республики Казахстан и местных исполнительных органов",
            "description_kz": "Қазақстан Республикасы Үкіметінің және жергілікті атқарушы органдар резервтерінің жұмсалуы жөніндегі ақпарат",
            "category": "МБ"
        },
        {
            "id": 14,
            "name_ru": "Приложение 16",
            "name_kz": "16-қосымша",
            "description_ru": "Сведения об остатках на счетах для учета сумм аккредитивов государственных учреждений",
            "description_kz": "Мемлекеттік мекемелердің аккредитивтер сомаларын есепке алу үшін шоттарындағы қалдықтар туралы мәліметтер",
            "category": "МБ"
        },
        {
            "id": 15,
            "name_ru": "Приложение 17",
            "name_kz": "17-қосымша",
            "description_ru": "Отчет о закрытии операционного дня по счетам в иностранной валюте",
            "description_kz": "Шетел валютасындағы шоттар бойынша операциялық күннің жабылуы туралы есеп",
            "category": "РБ"
        },
        {
            "id": 16,
            "name_ru": "Приложение 18",
            "name_kz": "18-қосымша",
            "description_ru": "Отчет о движении денег местного бюджета",
            "description_kz": "Жергілікті бюджеттің ақша қозғалысы туралы есеп",
            "category": "МБ",
            "load_url": "ba-report-18"
        },
        {
            "id": 17,
            "name_ru": "Приложение 19",
            "name_kz": "19-қосымша",
            "description_ru": "Расшифровка по счетам в иностранной валюте",
            "description_kz": "Шетел валютасының шоттардың толық жазылуы",
            "category": "МБ"
        },
        {
            "id": 18,
            "name_ru": "Приложение 20",
            "name_kz": "20-қосымша",
            "description_ru": "Отчет о закрытии операционного дня в Интегрированной информационной системе казначейства",
            "description_kz": "Қазынашылықтың интеграцияланған ақпараттық жүйесінде операциялық күннің жабылуы туралы есеп",
            "category": "РБ",
        },
        {
            "id": 19,
            "name_ru": "Приложение 21",
            "name_kz": "21-қосымша",
            "description_ru": "Информация по расходованию средств внешних займов и связанных грантов в рамках инвестиционных проектов",
            "description_kz": "Инвестициялық жобалар шеңберінде сыртқы қарыздар және байланысты гранттардың жұмсалуы бойынша ақпарат",
            "category": "МБ"
        },
        {
            "id": 20,
            "name_ru": "Приложение 23",
            "name_kz": "23-қосымша",
            "description_ru": "Справка по временно свободным бюджетным средствам (депозиты), размещенным в Национальном банке с Единого казначейского счета",
            "description_kz": "Бірыңғай қазынашылық шоттан Ұлттық банкісіне уақытша бос бюджеттік қаражаттарды (депозиттерді) орналастырылу бойынша анықтама",
            "category": "РБ"
        },
        {
            "id": 21,
            "name_ru": "Приложение 27",
            "name_kz": "27-қосымша",
            "description_ru": "Информация по кредитам, предоставленным из бюджета",
            "description_kz": "Бюджеттен берілген кредиттер бойынша ақпарат",
            "category": "МБ"
        }
    ]
    report_fields = [
        {key: "id", label: "№ П/П", sortable: false},
        {key: "load", label: "Скачать", sortable: false},
        {key: "name_ru", label: "Наименование файла", sortable: false},
        {key: "description_ru", label: "Описание файла", sortable: false},
    ]
    currDate = new Date();
    regionBase=[]


    async mounted() {
        await this.getObl();
        await this.loadRegions();
    }
    formatDate(date: Date): string {
        const yy = date.getFullYear().toString(); //
        const mm = String(date.getMonth() + 1).padStart(2, '0'); // Месяцы начинаются с 0, добавляем ведущий 0
        const dd = String(date.getDate()).padStart(2, '0'); // Добавляем ведущий 0
        return `${yy}-${mm}-${dd}`;
    }

    load_report(row: any) {
        this.download_report(row.load_url+"/"+this.formatDate(this.currDate), row.name_ru +'('+ this.formatDate(this.currDate)+')');
    }

    load_report_by_name(row: any) {
        if (row.category == "РБ") {
            this.do_notification();
        }
        this.download_report_by_name("/ba-report/"+ row.name_ru+'/'+this.formatDate(this.currDate), row.name_ru +'('+ this.formatDate(this.currDate)+')');
    }

    obl = null;

    async getObl() {
        try {
            await fetch('/api-py/get-budget-obl/' + this.$store.state._instanceCode)
                .then(response => response.json())
                .then(json => {
                    this.obl = json.obl;
                    // this.region = json.region;
                });
        } catch (error) {
            this.makeToast('danger', 'Ошибка запроса getObl', error.toString());
        }
    }
    currRegion: Region | null = null; // Define the type explicitly

    async loadRegions() { // справочник регионов
        try {
            const response = await fetch('/api-py/get-user-regions-by-obl/' + this.obl + '/' + this.$store.getters.user_uuid);
            this.regionBase = await response.json();
        } catch (error) {
            this.makeToast('danger', 'Ошибка запроса регионов', error.toString());
        }
    }

    async download_report_by_name(url: string, name: string) {
        try {
            const response = await axios.get(`/api-py/ba/${url}`, {responseType: 'blob'});
            const blob = new Blob([response.data]);
            const downloadUrl = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', name + '.xls');
            document.body.appendChild(link);
            link.click();
            window.URL.revokeObjectURL(downloadUrl);
            document.body.removeChild(link);
        } catch (error) {
            this.makeToast('danger', 'Ошибка загрузки файла', error.toString());
        }
    }

    async do_notification() {
        if (this.$i18n.locale == 'kk') {
            this.makeToast(
                "warning",
                "Назар аударыңыз! Бұл қосымша Республикалық Бюджет деңгейінде қалыптастырылады.",
                "Хабарлама"
            );
        } else {
            this.makeToast(
                "warning",
                "Обратите внимание! Данное приложение формируется на уровне Республиканского Бюджета.",
                "Уведомление"
            );
        }
    }


    async download_report(url: string, name: string) {
        let region: string = "330101"; // Default value
        if (this.currRegion !== null) {
            region = this.currRegion.code; // No more "never" error
        }
        try {
            const response = await axios.get(`/api-py/ba/${url}/${region}`, {responseType: 'blob'});
            const blob = new Blob([response.data]);
            const downloadUrl = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', name + '.xls');
            document.body.appendChild(link);
            link.click();
            window.URL.revokeObjectURL(downloadUrl);
            document.body.removeChild(link);
        } catch (error) {
            this.makeToast('danger', 'Ошибка загрузки файла', error.toString());
        }
    }


    makeToast(variant: string, tostbody: string, title: string): void {
        this.$bvToast.toast(tostbody, {
            title: title,
            variant: variant,
            autoHideDelay: 2000,
            solid: true
        });
    }
}
